@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  overflow: scroll;
  font-family: 'Josefin Sans', sans-serif;
  scroll-behavior: smooth;
}
body {
  background-color: black;
}

/* .logo {
  font-family: 'Fredoka';
} */

.logoShadow {
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.8);
}

.text {
  color: white;
}

::-webkit-scrollbar {
  width: 0;
  height: 0;
  background: transparent;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.swiper {
  --swiper-navigation-color: #ffffff !important;
  --swiper-pagination-color: #ffffff !important;
  --swiper-pagination-bullet-inactive-color: #a5a5a5;
  --swiper-navigation-size: 40px;
  --swiper-pagination-bullet-size: 10px;
  --swiper-pagination-bullet-width: 8px;
  --swiper-pagination-bullet-height: 8px;
  --swiper-pagination-fraction-color: #ffffff !important;
  --swiper-pagination-bottom: 54px;
}

.thumbs .swiper-slide {
  opacity: 0.6;
}

.thumbs .swiper-slide-thumb-active {
  opacity: 1;
}

.textShadow {
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.8);
}

.texto {
  writing-mode: vertical-lr;
  text-orientation: mixed;
}
